import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p>{`初心者の方は、この辺りを難しく感じるかもしれませんが、カリキュラムではこれから何度も何度も復習することになりますので、今はよくわからなくても大丈夫です。`}</p>
      <p>{`最初から完璧に理解しようとすると、`}<br parentName="p"></br>{`
`}{`ほとんどの人が挫折して`}<br parentName="p"></br>{`
`}{`かえって投げ出してしまうということが、`}<br parentName="p"></br>{`
`}{`脳科学の実験で証明されています。`}<br parentName="p"></br>{`
`}{`僕は、大手学習塾の社員として`}<br parentName="p"></br>{`
`}{`千人以上の指導に携わってきましたので、`}<br parentName="p"></br>{`
`}{`これに関しては断言できます。`}</p>
      <p>{`ですから、どうか「最初から100点に」ではなく、「わからない部分があっても、ひとまず放置して次に進む」という、新しい学習の習慣を身につけて下さい。`}<br parentName="p"></br>{`
`}{`なぜなら、それこそが「`}<strong parentName="p">{`MIXの75点の法則`}</strong>{`」そのものだからです。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      